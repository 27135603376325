@import './tailwind-base.css';

/* CSS KIT */
@import './css-kit-base.css';
@import './css-kit-components-buttons.css';
@import './css-kit-components-card.css';
@import './css-kit-components-forms.css';
@import './css-kit-components-html-content.css';
@import './css-kit-components-layout.css';
@import './css-kit-components-typography.css';
