/*
 *
 * Typography
 *
 */
@tailwind components;

@layer components {
  /*
   * Headings
   */

  .heading-page {
    @apply text-content-focus mt-3 text-3xl font-extrabold tracking-tight;
  }

  .heading-section {
    @apply text-content-focus text-2xl font-bold tracking-tight;
  }

  /*
   *
   * Links
   *
   */

  .link {
    @apply hover:text-primary-focus no-underline;
    &-sharp {
      @apply text-primary;
    }
    &-bold {
      @apply font-semibold;
    }
  }
}
