@tailwind components;

@layer components {
  .content-container {
    @apply mx-auto max-w-7xl px-4 sm:px-6 lg:px-8;

    /* @apply flex justify-center;
    & > * {
      @apply w-full px-2 xl:px-0;
      max-width: 1440px;
    }

    & > main {
      @apply py-8;
    } */
  }
}
