/*
 *
 * Buttons
 *
 */
@tailwind components;
@layer components {
  .btn {
    @apply bg-surface-suspend hover:bg-surface-focus hover:text-content-contrast focus:ring-surface-focus disabled:text-content-suspend disabled:hover:bg-surface-suspend disabled:hover:text-content-suspend text-content inline-flex items-center rounded px-4 py-2 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2;

    &-xs {
      @apply px-2.5 py-1.5 text-xs font-medium;
    }
    &-sm {
      @apply px-3 py-2 text-sm font-medium leading-4;
    }
    &-lg {
      @apply px-4 py-2 text-xl font-medium;
    }
    &-xl {
      @apply px-6 py-3 text-2xl font-medium;
    }

    &-outline {
      @apply border-surface-focus bg-surface disabled:text-content disabled:bg-surface-suspend disabled:hover:text-content-suspend border;
    }

    &-primary {
      @apply bg-primary text-primary-contrast hover:bg-primary-focus focus:ring-primary disabled:bg-primary-suspend disabled:text-primary-contrast disabled:hover:bg-primary-suspend disabled:hover:text-primary-contrast focus:outline-none focus:ring-2 focus:ring-offset-2;

      &.btn-outline {
        @apply border-primary text-primary bg-surface hover:border-primary-focus hover:bg-primary-suspend hover:text-primary-contrast disabled:text-primary-suspend disabled:bg-surface-suspend disabled:hover:text-primary-suspend disabled:hover:bg-surface-suspend border;
      }
    }

    &-secondary {
      @apply bg-secondary text-secondary-contrast hover:bg-secondary-focus focus:ring-secondary disabled:bg-secondary-suspend disabled:text-secondary-contrast disabled:hover:bg-secondary-suspend disabled:hover:text-secondary-contrast focus:outline-none focus:ring-2 focus:ring-offset-2;

      &.btn-outline {
        @apply border-secondary text-secondary bg-surface hover:border-secondary-focus hover:bg-secondary-suspend hover:text-secondary-contrast disabled:text-secondary-suspend disabled:bg-surface-suspend disabled:hover:text-secondary-suspend disabled:hover:bg-surface-suspend border;
      }
    }

    &-accent {
      @apply bg-accent text-accent-contrast hover:bg-accent-focus focus:ring-accent disabled:bg-accent-suspend disabled:text-accent-contrast disabled:hover:bg-accent-suspend disabled:hover:text-accent-contrast focus:outline-none focus:ring-2 focus:ring-offset-2;

      &.btn-outline {
        @apply border-accent text-accent bg-surface hover:border-accent-focus hover:bg-accent-suspend hover:text-accent-contrast disabled:text-accent-suspend disabled:bg-surface-suspend disabled:hover:text-accent-suspend disabled:hover:bg-surface-suspend border;
      }
    }

    &-ghost {
      @apply bg-transparent disabled:bg-transparent disabled:hover:bg-transparent;
      &.btn-outline {
        @apply hover:text-content-focus hover:border-surface-focus border border-transparent bg-transparent disabled:hover:border-transparent;
      }
    }

    &-link {
      @apply text-primary hover:text-primary-focus disabled:text-primary-suspend disabled:hover:text-primary-suspend bg-transparent underline hover:bg-transparent hover:no-underline focus:ring-0 disabled:no-underline disabled:hover:bg-transparent;
      &.btn-outline {
        @apply border-0 disabled:bg-transparent;
      }
    }

    &-info {
      @apply bg-info text-info-contrast hover:bg-info-focus focus:ring-info disabled:bg-info/25 disabled:text-info-contrast disabled:hover:bg-info/25 disabled:hover:text-info-contrast focus:outline-none focus:ring-2 focus:ring-offset-2;

      &.btn-outline {
        @apply border-info text-info bg-surface hover:border-info-focus hover:bg-info/25 hover:text-info-focus disabled:text-info-focus disabled:bg-surface-suspend disabled:hover:text-info-focus disabled:hover:bg-surface-suspend border;
      }
    }

    &-success {
      @apply bg-success text-success-contrast hover:bg-success-focus focus:ring-success disabled:bg-success/25 disabled:text-success-contrast disabled:hover:bg-success/25 disabled:hover:text-success-contrast focus:outline-none focus:ring-2 focus:ring-offset-2;

      &.btn-outline {
        @apply border-success text-success bg-surface hover:border-success-focus hover:bg-success/25 hover:text-success-focus disabled:text-success-focus disabled:bg-surface-suspend disabled:hover:text-success-focus disabled:hover:bg-surface-suspend border;
      }
    }

    &-error {
      @apply bg-error text-error-contrast hover:bg-error-focus focus:ring-error disabled:bg-error/25 disabled:text-error-contrast disabled:hover:bg-error/25 disabled:hover:text-error-contrast focus:outline-none focus:ring-2 focus:ring-offset-2;

      &.btn-outline {
        @apply border-error text-error bg-surface hover:border-error-focus hover:bg-error/25 hover:text-error-focus disabled:text-error-focus disabled:bg-surface-suspend disabled:hover:text-error-focus disabled:hover:bg-surface-suspend border;
      }
    }

    &-warning {
      @apply bg-warning text-warning-contrast hover:bg-warning-focus focus:ring-warning disabled:bg-warning/25 disabled:text-warning-contrast disabled:hover:bg-warning/25 disabled:hover:text-warning-contrast focus:outline-none focus:ring-2 focus:ring-offset-2;

      &.btn-outline {
        @apply border-warning text-warning bg-surface hover:border-warning-focus hover:bg-warning/25 hover:text-warning-focus disabled:text-warning-focus disabled:bg-surface-suspend disabled:hover:text-warning-focus disabled:hover:bg-surface-suspend border;
      }
    }
  }
}
