/*
 *
 * HTML content container
 *
 */
@tailwind components;

@layer components {
  .html-content-container {
    a {
      @apply text-primary underline hover:no-underline;
    }

    h1 {
      @apply my-4 text-3xl font-semibold;
    }

    h2 {
      @apply my-4 text-2xl font-semibold;
    }

    h3 {
      @apply my-4 text-xl font-semibold;
    }

    ul,
    ol {
      @apply my-4;
    }

    ul li {
      @apply mb-2;
      list-style: inside;
    }

    ol li {
      @apply mb-2;
      list-style: decimal inside;
    }

    table {
      @apply border-primary border-[3px] border-solid;
    }

    table tr {
      @apply border-primary border-[1px] border-solid;
    }

    table tr td {
      @apply border-primary border-[1px] border-solid p-1;
    }
  }

  .html-content-container-checklist {
    ul li:before {
      @apply text-primary relative mr-2 text-xl font-bold;
      content: '\2713';
      left: 0;
      bottom: 0;
    }
    ul li {
      list-style: none;
    }
  }

  .html-list-inline {
    ul li:not(:last-child):after {
      @apply bg-primary relative mx-2 inline-block rounded-full;
      content: '';
      min-height: 6px;
      max-height: 6px;
      min-width: 6px;
      max-width: 6px;
      bottom: 1px;
    }

    ul li {
      @apply inline;
      line-height: 26px;
      font-size: 13px;
    }
  }
}
