@tailwind components;

/**
 * Application Style Component
 */
@layer components {
  .form-styles {
    & [type='text'],
    & [type='email'],
    & [type='url'],
    & [type='password'],
    & [type='number'],
    & [type='date'],
    & [type='datetime-local'],
    & [type='month'],
    & [type='search'],
    & [type='tel'],
    & [type='time'],
    & [type='week'],
    & [multiple],
    & textarea,
    & select {
      @apply border-surface-focus text-content focus:border-primary focus:ring-primary disabled:bg-surface-suspend block w-full rounded-md shadow-sm outline-none;

      &[aria-invalid='true'] {
        @apply border-error focus:ring-error;
      }
    }

    & [type='checkbox'] {
      @apply border-surface-focus text-primary mr-2 rounded shadow-sm outline-0;
    }
    & [type='checkbox'].revert {
      @apply border-surface-focus bg-surface text-surface mr-2 rounded shadow-sm outline-0;
    }
    & [type='checkbox'].revert:focus {
      box-shadow: none !important;
    }
    & [type='checkbox'].revert:checked {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%232196f3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
      @apply text-surface;
    }

    & [type='radio'] {
      @apply border-surface-focus text-primary mr-2 rounded-full shadow-sm outline-0;
    }
    & [type='radio'].revert {
      @apply border-surface-focus bg-surface text-surface mr-2 rounded-full shadow-sm outline-0;
    }
    & [type='radio'].revert:focus {
      box-shadow: none !important;
    }
    & [type='radio'].revert:checked {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%232196f3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
      @apply text-surface;
    }

    & label {
      @apply text-content block text-left text-sm;

      & abbr {
        @apply text-error pl-px no-underline;
      }

      &[data-role='checkbox'] {
        @apply inline-flex items-center text-base;
        & > * {
          display: inline-block;
          &[type='checkbox'] {
            align-self: center;
          }
        }
      }

      &[data-role='radio'] {
        @apply inline-flex items-center text-base;
        & > * {
          display: inline-block;
          &[type='radio'] {
            align-self: center;
          }
        }
      }
    }

    & fieldset > legend {
      @apply text-content;
    }

    /* Field error message */
    & [role='alert'] {
      @apply text-error mt-0.5 text-sm;
    }

    /* Field helper text */
    & [role='tooltip'] {
      @apply text-content-suspend mt-0.5 text-sm;
    }

    & [data-role='input'] {
      @apply mt-1;
    }

    /* Input group */
    & [data-role='input-group'] {
      @apply mt-1 flex rounded-md shadow-sm;

      & input,
      textarea,
      select {
        @apply mt-0 rounded-none rounded-l-md last:rounded-r-md focus-within:z-10;
      }

      & > button {
        @apply relative first:rounded-r-none last:rounded-l-none focus:ring-offset-0;
      }

      & [data-role='input'] {
        @apply relative flex flex-grow items-stretch focus-within:z-10;
        & > input,
        textarea,
        select {
          @apply rounded-r-none;
        }
        &:last-child > input {
          @apply rounded-r-md;
        }
      }

      & [data-role='adornment-start'] {
        @apply pointer-events-none absolute inset-y-0 left-0 z-20 flex items-center pl-3;

        & > input,
        textarea,
        select {
          @apply pointer-events-auto mt-0 -ml-3 rounded-r-none border-transparent bg-transparent shadow-none;
        }
      }

      & [data-role='adornment-end'] {
        @apply pointer-events-none absolute inset-y-0 right-0 z-20 flex items-center pr-3;

        & > input,
        textarea,
        select {
          @apply pointer-events-auto mt-0 ml-auto -mr-3 rounded-l-none border-transparent bg-transparent shadow-none;
        }
      }

      & [data-role='addon-start'] {
        @apply border-surface-focus bg-surface-suspend/90 text-content inline-flex items-center rounded-l-md border border-r-0 px-3;

        & + input,
        textarea,
        select {
          @apply rounded-l-none;
        }
      }

      & [data-role='addon-end'] {
        @apply border-surface-focus bg-surface-suspend/90 text-content inline-flex items-center rounded-r-md border border-l-0 px-3;
      }
    }

    & select:required:invalid {
      @apply text-content-suspend;
    }

    & option[value=''][disabled] {
      @apply hidden;
    }

    & option {
      @apply text-content-focus;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 2rem;
      height: 1.15rem;

      & input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      & .slider {
        @apply bg-surface-suspend;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 0.4s;

        &:before {
          @apply bg-surface absolute;
          content: '';
          height: 0.9rem;
          width: 0.9rem;
          left: 0.13rem;
          bottom: 0.13rem;
          transition: 0.4s;
        }

        &.round {
          border-radius: 1.15rem;
        }
        &.round:before {
          border-radius: 50%;
        }
      }

      & input:checked + .slider {
        @apply bg-primary;
      }

      & input:focus + .slider {
        @apply shadow-primary shadow;
      }

      & input:checked + .slider:before {
        transform: translateX(0.9rem);
      }
    }
  }
}
