@tailwind base;

@layer base {
  body {
    @apply text-content bg-surface h-full;
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 16px;
  }
  @media print {
    body {
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
  }
  #__next {
    @apply h-full;
  }

  .h1 {
    @apply mb-8 text-3xl;
    /* font-size: 1.875rem;
    margin-bottom: 2.5rem;
    margin-top: 1.313rem;
    font-weight: 600; */
  }
  .h2 {
    font-size: 1.5rem;
  }
  .h3 {
    font-size: 1.125rem;
  }
  .h4 {
    font-size: 1.125rem;
  }
  .h5 {
    font-size: 0.938rem;
  }
  .h6 {
    font-size: 0.813rem;
  }
}
